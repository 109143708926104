import React from 'react';
import {Container, Grid, Typography} from '@mui/material';

function Home() {
  return (
    <Container maxWidth={'lg'}>
      <Grid container spacing={4} my={4}>
        <Grid item md={6}>
          <Typography variant={'h3'} style={{color: '#0089E2'}} my={2}>
            房仲神助手
          </Typography>
          <Typography variant={'h4'}>
            一款專為房仲設計的智能助理!<br/><br/>
            協助房仲在
            <span style={{ color: '#0089E2'}}>開發</span>
            <span style={{ color: '#0089E2'}}>銷售</span>期間，提供多項方便使用的工具，加速工作效率，決勝市場每一步！
          </Typography>
          <Typography fontFamily={'Jetbrains Mono'} variant={'h5'} my={6}>
            Powered by OpenAI / Llama2 / HuggingFace
          </Typography>

          <img style={{cursor: 'pointer'}} src="/image/download-android.png" alt="hero" width={'50%'} onClick={() => {
            window.open('https://play.google.com/store/apps/details?id=com.cochenct.cn_taiwan', '_blank');
          }}/>
          <br/>
          <img style={{cursor: 'pointer'}} src="/image/download-apple.png" alt="hero" width={'50%'} onClick={() => {
            window.open('https://play.google.com/store/apps/details?id=com.cochenct.cn_taiwan', '_blank');
          }}/>
        </Grid>
        <Grid item md={6} style={{ display: 'flex', justifyContent: 'center'}}>
          <img src="/image/hero.png" alt="hero" width={'70%'}/>
        </Grid>

      </Grid>
      <Grid mt={20}>
        <Typography variant={'h5'} style={{color: '#0089E2'}} mb={4}>
          您不用再花費大量時間！
        </Typography>
        <Typography variant={'h4'}>
          你可以透過TerraGo，進行...
        </Typography>
      </Grid>
      <Grid container spacing={3} justifyContent="center" marginTop={2}>
        <Grid item md={3}>
          <img src="/image/6.5_1.png" alt="screenshot-profile" width={'100%'}/>
        </Grid>
        <Grid item md={3}>
          <img src="/image/6.5_2.png" alt="screenshot-profile" width={'100%'}/>
        </Grid>
        <Grid item md={3}>
          <img src="/image/6.5_3.png" alt="screenshot-profile" width={'100%'}/>
        </Grid>
        <Grid item md={3}>
          <img src="/image/6.5_4.png" alt="screenshot-profile" width={'100%'}/>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Home;

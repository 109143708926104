import React from 'react';
import {Box, Container, Typography} from '@mui/material';

function PrivacyPolicy() {
  return (
    <Container maxWidth="lg" sx={{ padding: 2 }}>
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        本協議約定「房仲神助手」(以下簡稱本APP)與用戶(用戶是指使用本APP的個人或相關使用者)之間關於APP服務的權利義務。
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        請認真閱讀本用戶使用協議（以下簡稱"本協議"），確保你充分理解本協議中各條款。請你謹慎閱讀並選擇接受或不接受本協議。除非你接受本協議所有條款，否則請勿使用本APP。
      </Typography>
      <Box sx={{ height: 20 }} />
      
      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        一、服務內容
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        1.本APP提供之相關功能資料來源為開放資料，本APP無法確保資料永遠為最新狀態，亦無法確保資料正確性。
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        2.本APP多為查詢服務，如地籍資料、圖層資料等等，目的為提供使用者參考用，不得作為任何形式證明或主張，如發生任何問題，本APP不承擔任何責任。
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        3.資料使用責任：使用者不得濫用本平台提供的資料，也不得對資料進行變造或偽造。若因故意或過失不當使用資料而對第三方造成損害，使用者需依相關法律規定負責，並對受損害者進行賠償。
      </Typography>
      <Box sx={{ height: 20 }} />

      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        二、用戶個人隱私資料保護
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        1.本APP不主動蒐集或儲存個人資料，使用本APP過程中僅會要求地理資訊位置之權限，但僅於APP中定位使用。
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        2.資料保留與刪除：您的個人資料將被保存至達成蒐集目的為止，或法律要求的最長保存期限內。一旦資料不再需要，我們將安全地刪除或匿名化處理。
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        3.使用者的權利：您有權查閱、更正或要求刪除我們所持有的您的個人資料。如果您希望行使這些權利，請透過以下聯絡方式與我們聯繫。
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        4.資料之保護：本APP系統均設有防護等相關的各項資訊安全設備及必要的安全防護措施，加以保護APP及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的GPS資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        5.與第三人共用個人資料之政策：本APP絕不會提供、交換、出租或出售任何您的GPS資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。
      </Typography>
      <Box sx={{ height: 20 }} />

      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        三、智慧財產權
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        1.本APP為我運營方的所有權及知識產權權利人，相關知識產權由營運方主理。
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        2.用戶不得修改、改編、翻譯本APP服務所使用的軟體、技術、界面設計等，或未經營運方授權同意創作與之相關的作品，不得通過反向工程、反編譯、反匯編或其他類似行為獲得其源代碼，除非法律允許或獲得本APP書面授權，否則由此引起的一切法律後果由用戶負責，營運方將保留追究違約方法律責任的權利。
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        3.用戶不得惡意修改、重製、傳播本APP服務所使用的軟體、技術、界面設計等。否則，用戶自行承擔因此而造成對其他人的損害，或者造成對本APP營運方形象損害，要承擔相應的法律責任。
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        4.用戶不得擅自刪除、掩蓋或更改本APP的版權聲明或其它權利聲明。本APP所有設計圖樣以及其他圖樣、產品及服務名稱，不得使用、重製或用作其他用途。
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        5.為更好維護本APP生態，我運營方保留在任何時間內以任何方式處置上述由本APP運營方享受所有權及知識產權的產品或內容，包括但不限於修訂、屏蔽、刪除或其他任何法律法規允許的處置方式。
      </Typography>
      <Box sx={{ height: 20 }} />

      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        四、服務風險聲明
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        1.用戶理解並同意，本APP服務涉及到科技網路與移動通訊等服務，可能會受到各個環節不穩定因素的影響。因此服務存在因上述不可抗力、電腦病毒或駭客攻擊、系統不穩定、用戶所在位置、用戶關機、GSM網絡、科技網絡、通訊線路原因等造成的服務中斷或不能滿足用戶要求的風險。使用本服務的用戶須承擔以上風險，營運方對服務之及時性、安全性、準確性不作擔保，對因此導致用戶不能發送和接受消息、或傳遞錯誤，個人設定之時效、未予儲存或其他問題不承擔任何責任。
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        2.對於系統發生故障影響到本APP服務的正常運行，營運方承諾會及時處理並盡可能修復。但用戶因此而產生的經濟和精神損失，營運方不承擔任何責任。此外，本APP保留不經事先通知為優化、升級或其他目的暫停本APP服務任何部分或全部的權利。
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        3.本APP僅在合理範圍內提供服務，並不對因技術問題、不可抗力等因素導致的損害承擔責任。
      </Typography>
      <Box sx={{ height: 20 }} />

      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        五、協議更新
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        1.本協議可能會隨時更新，更新後的協議條款一旦公布即代替原來的協議條款，恕不再另行通知，也請用戶隨時關注本APP用戶協議的更新情況，以免造成不必要的誤解和糾紛。在修改協議條款後，如果用戶不接受修改後的條款，請立即停止使用本APP提供的服務，用戶繼續使用本APP提供的服務將被視為已充分閱讀、理解並願意接受修改後的協議。
      </Typography>
      <Box sx={{ height: 20 }} />
      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        六、服務條款與隱私權政策之修訂
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        為了更確實執行個人資訊的保護，本APP得隨時修訂服務條款與隱私權政策。
      </Typography>
      <Box sx={{ height: 20 }} />
      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        七、聯繫管道
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        對於本站之隱私權政策有任何疑問，或者想提出變更、移除個人資料之請求，請前往本站「Support」頁面寄送信件。
      </Typography>
      <Box sx={{ height: 20 }} />

      <Typography variant="body1" sx={{ fontSize: 16, color: 'gray' }}>
        日期:2025年3月7日
      </Typography>
      <Box sx={{ height: 70 }} />
    </Container>
  );
}

export default PrivacyPolicy;

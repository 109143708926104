import React from 'react';
import {Box, Container, Typography} from '@mui/material';

function TermsOfUse() {
  return (
    <Container maxWidth="lg" sx={{ padding: 2 }}>
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        當您使用本APP時，即表示您已閱讀、瞭解並同意接受本約定書之所有內容。本APP有權於任何時間修改或變更本約定書之內容，建議您隨時注意該等修改或變更。您於任何修改或變更後繼續使用本APP服務，視為您已閱讀、瞭解並同意接受該等修改或變更。

        若您為未滿十八歲，請勿使用本APP；
        不實登錄經查將一律註銷會員資格。若產生法律相關問題及事項，所有責任歸屬不實登錄者。
      </Typography>

      <Box sx={{ height: 20 }} />
      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        您的註冊義務
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        為了能使用本服務，您同意以下事項：
        依本服務註冊頁面之提示提供您本人正確、最新及完整的資料。維持並更新您個人資料，確保其為正確、最新及完整。若您提供任何錯誤或不實的資料，本APP有權暫停或終止您的帳號，並拒絕您使用本服務之全部或一部。
      </Typography>


      <Box sx={{ height: 20 }} />
      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        使用者的守法義務及承諾
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        您承諾絕不為任何非法目的或以任何非法方式使用本APP，並承諾遵守中華民國相關法規及一切使用網際網路之國際慣例。
        您若係中華民國以外之使用者，並同意遵守所屬國家或地域之法令。
        您同意並保證不得利用本服務從事侵害他人權益或違法之行為，包括但不限於：
        <ul>
          <li>公布或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案。</li>
          <li>侵害他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利。</li>
          <li>違反依法律或契約所應負之保密義務。</li>
          <li>冒用他人名義使用本服務。</li>
          <li>傳輸或散佈電腦病毒濫發廣告郵件。</li>
          <li>其他本APP有正當理由認為不適當之行為，本APP得依實際執行情形，增加、修改或終止相關活動，並選擇最適方式告知會員，會員即應同意並配合之，否則本APP有權自行為任何適當之處置措施。</li>
        </ul>
        發生下列情形之一時，本APP有權可以停止、中斷提供服務，且不負賠償責任：
        <ul>
          <li>本APP網站電子通信設備進行必要之保養及施工時；</li>
          <li>發生突發性之電子通信設備故障時；</li>
          <li>本APP申請之電子通信服務被停止，無法提供服務時；</li>
          <li>由於天災等不可抗力之因素致使本APP無法提供服務時。</li>
        </ul>
      </Typography>

      <Box sx={{ height: 20 }} />
      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        資料來源與使用責任
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        本應用程式不代表或隸屬於任何政府機構，一切資料來源係經「地政資訊整合資訊服務平台」(https://cop.land.moi.gov.tw/SiteInfo/Statement/DataAuthStatement)授權下使用。
        <br/>
        資料參考性：本服務提供的地籍定位資訊內容僅供參考，無法保證資料的完全準確性，亦不具備文書證明效力。若有更精確的需求，建議您聯繫當地地政局進行確認。
        <br/>
        資料使用責任：使用者不得濫用本平台提供的資料，也不得對資料進行變造或偽造。若因故意或過失不當使用資料而對第三方造成損害，使用者需依相關法律規定負責，並對受損害者進行賠償。
      </Typography>

      <Box sx={{ height: 20 }} />
      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        智慧財產權的保護
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        本APP所使用之軟體或程式、網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計，均由本APP或其他權利人擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。任何人不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。若您欲引用或轉載前述軟體、程式或網站內容，必須取得本APP或其他權利人的事前書面同意。尊重智慧財產權是您應盡的義務，如有違反，您應對本APP負損害賠償責任（包括但不限於訴訟費用及律師費用等）。
        <br/>
        若您無合法權利得授權他人使用、修改、重製、公開播送、改作、散布、發行、公開發表某資料，並將前述權利轉授權第三人，請勿擅自將該資料上載、傳送、輸入或提供至本APP。任何資料一經您上載、傳送、輸入或提供至本APP時，視為您已允許本APP無條件使用、修改、重製、公開播送、改作、散布、發行、公開發表該等資料，並得將前述權利轉授權他人，您對此絕無異議。您並應保證於本APP所使用、修改、重製、公開播送、改作、散布、發行、公開發表、轉授權該等資料，不致侵害任何第三人之智慧財產權，否則應對本APP負損害賠償責任（包括但不限於訴訟費用及律師費用等）。
      </Typography>

      <Box sx={{ height: 20 }} />
      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        拒絕或終止您的使用
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        您同意本APP得基於維護交易安全之考量，因任何理由，包含但不限於過久未使用或使用頻率過低，或違反本服務條款的明文規定及精神，終止您的密碼、帳號（或其任何部分）或本服務之使用，並將本服務內任何「會員內容」加以移除並刪除，亦得在已電話或電子郵件等方式通知之情形下，隨時終止本服務或任何部分。此外，您同意若本服務之使用被終止，本APP對您或任何第三人均不承擔任何責任。
        <br/>
      </Typography>


      <Box sx={{ height: 20 }} />
      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        軟體更新政策
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        我們會持續改善軟體功能和政策，藉此提升使用者體驗，並打造更便利及安全的使用環境。因應不斷精進的軟體功能更新，本APP有權要求使用者強制更新APP，以期滿足更好的使用體驗。
      </Typography>

      <Box sx={{ height: 20 }} />
      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        準據法與管轄法院
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        本約定書之解釋與適用，以及與本約定書有關的爭議，均應依照中華民國法律予以處理。並由臺灣臺北地方法院作為第一審管轄法院。
      </Typography>
      <Box sx={{ height: 70 }} />
    </Container>
  );
}

export default TermsOfUse;
